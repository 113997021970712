import * as React from 'react'
import { connect } from 'react-redux'
import { updateSettings, updateWorkspace } from '../../../store/authentication/actions'
import SettingsController, { ISettingsForm } from '../../../controllers/SettingsController'
import { Dispatch } from 'redux';
import { AppState } from '../../../store'
import { ActiveStorageController, WorkspaceController } from '../../../controllers'
import Notification from '../../../utilities/Notification'
import TooltipError from '../../../components/Tooltips/ErrorTooltip'
import Button from '../../../components/Button/Button'
import Select from '../../../components/Form/Select'
import Switch from '../../../components/Form/Switch'
import AvatarEditor from '../../../components/Avatar/AvatarEditor'
import ScrollToTopOnMount from '../../../components/Effects/ScrollToTopOnMount'
import PageLoader from '../../../components/Page/PageLoader';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CurrentUser, DisplayableError, Settings, Workspace } from '../../../types';
import styled from 'styled-components';
import Panel from '../../../components/Panel/Panel';
import Title from '../../../components/Settings/Title';

const AccountNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input:first-child {
    max-width: 100px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  input:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  updateWorkspace: typeof updateWorkspace
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation
interface IState {
  workspace: Workspace
  setting: Settings
  errors: DisplayableError[]
  form: ISettingsForm | null
}

class CompanyInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { currentUser: { workspace } } = props
    const { setting } = workspace

    this.state = {
      workspace: workspace,
      setting: setting,
      errors: [],
      form: null
    }

    // Form inputs
    this.onBusinessLogoChange = this.onBusinessLogoChange.bind(this)
    this.onBusinessLogoDeleteClick = this.onBusinessLogoDeleteClick.bind(this)
    this.onBusinessNameChange = this.onBusinessNameChange.bind(this)
    this.onBusinessDescriptionChange = this.onBusinessDescriptionChange.bind(this)
    this.onVatNumberChange = this.onVatNumberChange.bind(this)
    this.onBicCodeChange = this.onBicCodeChange.bind(this)
    this.onAccountNumberChange = this.onAccountNumberChange.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this)
    this.onWebsiteChange = this.onWebsiteChange.bind(this)
    this.onAppointmentUrlChange = this.onAppointmentUrlChange.bind(this)
    this.onStreetChange = this.onStreetChange.bind(this)
    this.onCityChange = this.onCityChange.bind(this)
    this.onProvinceChange = this.onProvinceChange.bind(this)
    this.onZipcodeChange = this.onZipcodeChange.bind(this)
    this.onCountryChange = this.onCountryChange.bind(this)

    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentWillMount() {
    SettingsController
      .getForm()
      .then((form) => {
        this.setState({
          form: form
        })
      })
      .catch(console.error)
  }

  onBusinessLogoChange(acceptedFiles) {
    const { updateWorkspace } = this.props

    const logo = acceptedFiles[0]

    try {
      ActiveStorageController.uploadPublic(logo, async (error, blob) => {
        if (error) { console.error(error) }
        if (blob) {
          const updatedWorkspace = await WorkspaceController.update({
            ...this.state.workspace,
            business_logo: blob.signed_id
          })

          updateWorkspace(updatedWorkspace)

          this.setState({ workspace: updatedWorkspace })
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onBusinessLogoDeleteClick() {
    const { currentUser: { workspace } } = this.props;

    const { updateWorkspace } = this.props

    try {
      const updatedWorkspace = await WorkspaceController.update({
        ...workspace,
        business_logo: null
      })

      this.setState({ workspace: updatedWorkspace })
      updateWorkspace(updatedWorkspace)
    } catch (ex) {
      console.error(ex)
    }
  }

  onBusinessNameChange(e) {
    const businessName = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.business_name = businessName;

    this.setState({
      workspace: workspace
    });
  }

  onBusinessDescriptionChange(e) {
    e.preventDefault()

    const businessDescription = e.currentTarget.value

    const { workspace } = this.state;

    workspace.description = businessDescription;

    this.setState({
      workspace: workspace
    });
  }

  onVatNumberChange(e) {
    const vatNumber = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.vat_number = vatNumber;

    this.setState({
      workspace: workspace
    });
  }

  onBicCodeChange(e) {
    const { workspace } = this.state;

    this.setState({
      workspace: {
        ...workspace,
        bic_code: e.currentTarget.value
      }
    })
  }

  onAccountNumberChange(e) {
    const accountNumber = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.account_number = accountNumber;

    this.setState({
      workspace: workspace
    });
  }

  onEmailChange(e) {
    const email = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.email = email;

    this.setState({
      workspace: workspace
    });
  }

  onPhoneNumberChange(e) {
    const phoneNumber = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.phone_number = phoneNumber;

    this.setState({
      workspace: workspace
    });
  }

  onWebsiteChange(e) {
    const website = e.currentTarget.value

    const { workspace } = this.state;

    workspace.website = website;

    this.setState({
      workspace: workspace
    });
  }

  onAppointmentUrlChange(e) {
    e.preventDefault()

    const appointmentUrl = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.appointment_url = appointmentUrl

    this.setState({
      workspace: workspace
    });
  }

  onStreetChange(e) {
    const street = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.street = street;

    this.setState({
      workspace: workspace
    });
  }

  onCityChange(e) {
    const city = e.currentTarget.value;

    const { workspace } = this.state;

    workspace.city = city;

    this.setState({
      workspace: workspace
    });
  }

  onProvinceChange(e) {
    const { workspace } = this.state;

    const province = e.currentTarget.value;

    workspace.province = province;

    this.setState({
      workspace: workspace
    });
  }

  onZipcodeChange(e) {
    const { workspace } = this.state;
    const zipCode = e.currentTarget.value;

    workspace.zip_code = zipCode;

    this.setState({
      workspace: workspace
    });
  }

  onCountryChange(e) {
    const { workspace } = this.state;
    const countryCode = e.currentTarget.value;

    workspace.country_code = countryCode;

    this.setState({
      workspace: workspace
    });
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { workspace, setting } = this.state
    const { updateWorkspace, updateSettings, t } = this.props

    try {
      let errors: DisplayableError[] = []
      const workspaceResponse = await WorkspaceController.update(workspace)

      if (workspaceResponse.errors) {
        errors = [...workspaceResponse.errors]
      }
      else {
        const workspace = workspaceResponse;

        updateWorkspace(workspace)

        this.setState({
          workspace: workspace
        });
      }

      const settingResponse = await SettingsController.update(setting)

      if (settingResponse.errors) {
        errors = [...settingResponse.errors]
      }
      else {
        const setting = settingResponse as Settings

        updateSettings(setting)

        this.setState({
          setting: setting
        });
      }

      if (errors.length > 0) {
        Notification.notifyError(t('CompanyInfo::Oops something went wrong!'));
        this.setState({ errors: errors })
      } else {
        Notification.notifySuccess(t('CompanyInfo::Info successfully updated.'));
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t } = this.props
    const { workspace, setting, errors, form } = this.state

    if (form) {
      const { countries } = form

      return (
        <form onSubmit={this.onFormSubmit}>
          <ScrollToTopOnMount />
          <Title>{t('CompanyInfo::Company details')}</Title>
          <Panel>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item' style={{ marginTop: 20, marginBottom: 35 }}>
                  <AvatarEditor
                    uri={workspace.business_logo_url}
                    onChange={this.onBusinessLogoChange}
                    onDelete={this.onBusinessLogoDeleteClick}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Company name')}</label>
                  <input type='text' name='business_name' value={workspace.business_name} placeholder={`Acme Corporation`} onChange={this.onBusinessNameChange} />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Description')}</label>
                  <textarea
                    name='description'
                    value={workspace.description}
                    placeholder={t('CompanyInfo::Business description')}
                    onChange={this.onBusinessDescriptionChange}
                    style={{ minHeight: 100 }}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::VAT number')}</label>
                  <input
                    type='text'
                    name='vat_number'
                    value={workspace.vat_number}
                    placeholder={`BE123.321.123`}
                    onChange={this.onVatNumberChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Peppol ID')}</label>
                  <input
                    type='text'
                    name='peppol_id'
                    value={workspace.peppol_id}
                    placeholder={t('CompanyInfo::Peppol ID')}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Account number')}</label>
                  <AccountNumberContainer>
                    <input
                      type='text'
                      name='bic_code'
                      value={workspace.bic_code}
                      placeholder='GKCCBEBB'
                      onChange={this.onBicCodeChange}
                    />

                    <input
                      type='text'
                      name='vat_number'
                      value={workspace.account_number}
                      placeholder={`BE11679123456748`}
                      onChange={this.onAccountNumberChange}
                    />
                  </AccountNumberContainer>
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Email')}*</label>
                  <input
                    type='text'
                    name='email'
                    value={workspace.email}
                    placeholder='janedoe@gmail.com'
                    onChange={this.onEmailChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Phone number')}</label>
                  <input
                    type='text'
                    name='phone_number'
                    value={workspace.phone_number}
                    placeholder={`+32 0800 10 800`}
                    onChange={this.onPhoneNumberChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Website')}</label>
                  <input
                    type='text'
                    name='website'
                    value={workspace.website}
                    placeholder={`http://mywebsite.com`}
                    onChange={this.onWebsiteChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Contact link')}</label>
                  <input
                    type='text'
                    name='website'
                    value={workspace.appointment_url}
                    placeholder={`https://www.mijnwebsite.be/contact`}
                    onChange={this.onAppointmentUrlChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('CompanyInfo::Street')}</label>
                  <input
                    type='text'
                    name='street'
                    value={workspace.street}
                    placeholder={t('CompanyInfo::Street')}
                    onChange={this.onStreetChange}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='grid-cell with-4col'>
                    <div className='form-item'>
                      <label>{t('CompanyInfo::City')}</label>
                      <input
                        type='text'
                        name='city'
                        value={workspace.city}
                        placeholder={t('CompanyInfo::City')}
                        onChange={this.onCityChange}
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-4col'>
                    <div className='form-item'>
                      <label>{t('CompanyInfo::State / Province')}</label>
                      <input
                        type='text'
                        name='city'
                        value={workspace.province}
                        placeholder={t('CompanyInfo::State / Province')}
                        onChange={this.onProvinceChange}
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-4col'>
                    <div className='form-item'>
                      <label>{t('CompanyInfo::Postal')}</label>
                      <input
                        type='text'
                        name='city'
                        value={workspace.zip_code}
                        placeholder={t('CompanyInfo::Postal')}
                        onChange={this.onZipcodeChange}
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('CompanyInfo::Country')}</label>
                      <Select
                        name='country_code'
                        value={workspace.country_code}
                        options={countries.map(country => {
                          return {
                            key: country.value, label: country.label, value: country.value
                          }
                        })}
                        onChange={this.onCountryChange}
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-12col'>
                    <div className='field-actions'>
                      <input type='submit' style={{ display: 'none' }} />
                      <div className='popover-wrapper'>
                        <TooltipError
                          errors={errors}
                          onDismiss={() => this.setState({ errors: [] })}
                        />
                        <Button type='success' text={t('CompanyInfo::Save')} onClick={this.onFormSubmit} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </Panel>
        </form>
      )
    }
    else {
      return (
        <PageLoader />
      )
    }
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    updateWorkspace: (workspace: Workspace) => dispatch(updateWorkspace(workspace)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyInfo))